import React from "react";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
	dateDiffInDays,
	resturctureAccess,
	_paths_,
} from "app/utils/appHelpers";
import { useAuthState, useAuthDispatch, contextSignOut } from "app/providers/AuthContext";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "app/layouts/layouts";


const SPARK_BASE_URL =
	process.env.REACT_APP_ENV === "production"
		? process.env.REACT_APP_PROD_BASE_URL
		: process.env.REACT_APP_SANDBOX_BASE_URL;
const instance = axios.create({ baseURL: SPARK_BASE_URL });

export const AuthMiddleware = ({ fallbackPath, children }) => {
	const { user, status, token, allAccesses } = useAuthState();
	const dispatch = useAuthDispatch();
	// console.log(user, "user");
	const { pathname } = useLocation();
	const [isAllowed, setIsAllowed] = React.useState( true );
	const navigate = useNavigate();

	const { setActiveLayout } = useJumboApp();

	async function load(permissions, accesses) {
		const unstructuredUserMenus = (
			await resturctureAccess(accesses, permissions, "page")
		).unstructuredUserMenus;
		// dispatch()

		// console.log(unstructuredUserMenus[0].path, "unstructuredUserMenus");
		for (const menus of unstructuredUserMenus) {
			if (menus.path === "dashboard") {
				setIsAllowed(true);
				return;
			}
		}

		if (!unstructuredUserMenus.map((x) => x.path).includes(pathname)) {
			setIsAllowed(false);
		}
	}

	React.useEffect(() => {
		if (
			![_paths_.SIGN_IN, _paths_.SIGN_UP, _paths_.SIGN_IN_HOME].includes(
				pathname
			) &&
			user
		) {
			const currentDate = new Date();
			const dateOfLogin = new Date(token.accessTokenExpiresIn);
			if (dateOfLogin && currentDate) {
				const dateDifference = dateDiffInDays(dateOfLogin, currentDate);
				if (dateDifference > 0) {
					contextSignOut(dispatch, navigate, _paths_.SIGN_IN);
				}
			}
			setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);

			const permissions = user.permissions;
			// console.log(permissions, "permissions");

			const accesses = allAccesses;
			// console.log(accesses, "accesses?");

			load(permissions, accesses);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setActiveLayout]);

	const bearerToken = token?.accessToken;
	const header = {
		Authorization: `Bearer ${bearerToken}`,
	};

	instance.interceptors.request.use((request) => {
		request.headers = header;
		return request;
	});

	instance.interceptors.response.use(
		(res) => res,
		(error) => {
			if (error && error.response) {
				// const { status } = error.response;
				// if (status === ResponseCodes.Unauthorized) {
				//     navigate(
				//         fallbackPath,
				//         { prevPath: pathname }
				//     );
				// }
			}
			return Promise.reject(error);
		}
	);

	const isLoading = status === "pending";

	if (isLoading) {
		return (
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		);
	}

	if (user) {
		if (isAllowed) {
			return children;
		}

		return (
			<div>
				<h1 style={{ fontSize: "28px", textAlign: "center" }}>
					Hi {user.firstName} 👋
				</h1>

				{user?.permissions && user?.permissions?.length ? (
					<div>
						<h2 style={{ fontSize: "20px", textAlign: "center" }}>
							Sorry you don't have access to this page 🙊
						</h2>
						<h3
							style={{
								fontSize: "16px",
								textAlign: "center",
								fontWeight: "600",
								opacity: "50%",
							}}
						>
							To continue, please select a page from the sidebar, refresh or
							contact your administrator 🤭
						</h3>
					</div>
				) : (
					<div>
						<h2 style={{ fontSize: "20px", textAlign: "center" }}>
							You don't have access to any page. Please contact your
							administrator 🙊
						</h2>
					</div>
				)}
			</div>
		);
	}

	return <Navigate to={fallbackPath} />;
};

export const AdminAPI = {
	get: async (query) => {
		// return axios.post(`${process.env.REACT_APP_API_DELIVERY}/deliveries`, data);
	},
	getById: async (id) => {},
	/**
	 * Post endpoint to create a new admin
	 * @param {object} data
	 */
	signIn: async (data) => {
		return instance.post(`/admin/auth/login`, data);
	},
	addAdmin: async (data) => {
		return instance.post(`/admin/auth/register`, data);
	},
	updateAdmin: async (data) => {
		return instance.put(`/admin/auth/register`, data);
	},
	confirmOtp: async (data) => {
		return instance.post(`/admin/auth/confirm-otp`, data);
	},
	createPassword: async (query) => {
		return instance.post(`/admin/auth/create-password`, query.data, {
			headers: {
				Authorization: `Bearer ${query.token}`,
			},
		});
	},
	updatePassword: async (data) => {
		return instance.post(`/admin/auth/update-password`, data);
	},
};

export const DashboardAPI = {
	get: async (query) => {
		return instance.get(`/admin/dashboard/user/onboarding?mode=${query}`);
	},
	getAllMetrics: async (data, isLoadingInBatch = false, startDate, endDate) => {
		const temp = [];
		if (isLoadingInBatch) {
			for (const key of data) {
				const res = await instance.get(key.url);
				temp.push({ ...key, res: res.data, isLoadingInBatch });
			}
		}

		if (!isLoadingInBatch) {
			for (const key of data) {
				const res = instance.get(key.url);
				temp.push({ ...key, res, isLoadingInBatch });
			}
		}

		return temp;
	},
	/**
	 * Get metric by metric id
	 * @param {number} id
	 */
	getById: async (id) => {
		return instance.get(`/admin/dashboard/user/onboarding`);
	},
	getMostActiveUsers: async ({ startDate, endDate }) => {
		return instance.get(
			`/admin/user/metrics?mode=MOST_ACTIVE_USERS&startDate=${startDate}&endDate=${endDate}`
		);
	},
};

export const UserAPI = {
	get: async ( query, numberOfItems, pageNumber, orderBy ) => {
		const _query = query ?? "";
		const _numberOfItems = numberOfItems ?? 10;
		const _pageNumber = pageNumber ?? 1;
		const _orderBy = orderBy ?? "";
		return instance.get(`/admin/user?searchQuery=${_query}&numberOfItems=${_numberOfItems}&pageNumber=${_pageNumber}&orderBy=${_orderBy}`);
	},
	/**
	 * Get user by user id
	 * @param {number} id
	 */
	getById: async (id) => {
		return instance.get(`/admin/user?searchQuery=${id}`);
	},
	patchUserStatus: async (userId, status) => {
		return instance.patch(`/admin/user/${userId}/${status}`);
	},
};

export const ClubAPI = {
	get: async (query) => {
		return instance.get(`/admin/club`);
	},
	/**
	 * Get club by club id
	 * @param {number} id
	 */
	getById: async (id) => {
		return instance.get(`/admin/club/${id}`);
	},
	patchClubStatus: async (id, status) => {
		return instance.patch(`/admin/club/${id}/${status}`);
	},
	removeApprovers: async (query) => {
		return instance.post(
			`/admin/club/${query.clubId}/remove-approvers`,
			query.payload
		);
	},
};
export const ComplianceAPI = {
	get: async (query) => {
		return instance.get(`admin/compliance`);
	},
	/**
	 * Update config by config id and data
	 * @param {number} id
	 */
	update: async (id, data) => {
		return instance.get(`admin/config/compliance/${id}`, data);
	},
};
export const MoneyRequestsAPI = {
	get: async (query) => {
		return instance.get(
			`/admin/transaction/money-request/fetch?startDate=${query.startDate}&endDate=${query.endDate}`
		);
	},
	/**
	 * Get money request by id
	 * @param {number} id
	 */
	getById: async (id) => {
		return instance.get(`/admin/transaction/money-request/fetch/${id}`);
	},
	getByUser: async (id) => {
		return instance.get(`/admin/transaction/money-request/by-user/${id}`);
	},
	// patchMoneyRequestStatus: async (id, status) => {
	// 	return instance.patch(
	// 		`/admin/transaction/money-request/fetch/${id}/${status}`
	// 	);
	// },
};
export const TransactionsAPI = {
	get: async (query) => {
		return instance.get(
			`/admin/transaction?startDate=${query.startDate}&endDate=${query.endDate}`
		);
	},
	// get: async (query) => {
	// 	return instance.get(
	// 		`/admin/transaction?startDate=${query.start}&endDate=${query.end}`
	// 	);
	// },
	/**
	 * Get club by club id
	 * @param {number} id
	 */
	getById: async (id) => {
		return instance.get(`/admin/transaction/${id}`);
	},
};

export const PermissionsAPI = {
	getAdmins: async () => {
		return instance.get(`/admin`);
	},
	/**
	 * Get admin by admin id
	 * @param {number} id
	 */
	getPermissions: async (id) => {
		return instance.get(`/admin/auth/permission`);
	},
	createPermission: async (data) => {
		return instance.post(`/admin/auth/permission`, data);
	},
	updatePermission: async (query) => {
		return instance.put(`/admin/auth/permission/${query.id}`, query.data);
	},
	getPermissionsByUserId: async (id) => {
		return instance.get(`/admin/auth/get-permissions-by-user/${id}`);
	},
	assignUserPermisssions: async (data) => {
		return instance.post(`/admin/auth/assign-permissions`, data);
	},
	removeUserPermission: async (data) => {
		return instance.post(`/admin/auth/remove-user-permission`, data);
	},
	updateUserPermissions: async (id, data) => {
		return instance.put(`/admin/auth/permission/${id}`, data);
	},
	createAccess: async (data) => {
		return instance.post(`/admin/auth/access`, data);
	},
	getAccess: async () => {
		return instance.get(`/admin/auth/access`);
	},
	updateAccess: async (id, data) => {
		return instance.put(`/admin/auth/access/${id}`, data);
	},
};

export const GetCountriesAPI = {
	get: async () => {
		return instance.get(`/admin/account/countries-id-types`);
	},
};

export const CheckIDNumberAPI = {
	post: async (payload) => {
		return await instance.post(`/admin/account/check-id-number`, payload);
	},
};

export const UploadKycAPI = {
	post: async (payload) => {
		return instance.post(`/admin/account/upload-kyc`, payload);
	},
	overrideKYC: async (userId) => {
		return instance.put(`/admin/user/toggleId/${userId}`, null);
	},
};

export const NotificationsAPI = {
	get: async () => {
		return instance.get(`/admin/config/key`);
	},
	put: async (data, id) => {
		return instance.put(`/admin/config/key/${id}`, data);
	},
};

export const AdminDealsAPI = {
	getCatalogs: async () => {
		return instance.get(`/admin/deals/catalog`);
	},
	getCatalogById: async (id) => {
		return instance.get(`/admin/deals/catalog/${id}`);
	},
	createCatalog: async (payload) => {
		return instance.post(`/admin/deals/catalog`, payload);
	},
	duplicateCatalog: async (id) => {
		return instance.post(`/admin/deals/catalog/clone`, {
			catalogId: id,
		});
	},
	updateCatalog: async ({ id, payload }) => {
		return instance.put(`/admin/deals/catalog/${id}`, payload);
	},
	updateItemInACatalog: async ({ catalogId, itemId, payload }) => {
		return instance.put(
			`/admin/deals/catalog/${catalogId}/item/${itemId}`,
			payload
		);
	},
	deleteItemFromCatalog: async ({ catalogId, itemId }) => {
		return instance.post(`/admin/deals/catalog/${catalogId}/item/${itemId}`);
	},
	getItems: async () => {
		return instance.get(`/admin/deals/item`);
	},
	getItemById: async (id) => {
		return instance.get(`/admin/deals/item/${id}`);
	},
	createItem: async (payload) => {
		return instance.post(`/admin/deals/item`, payload);
	},
	updateItem: async ({ id, payload }) => {
		return instance.put(`/admin/deals/item/${id}`, payload);
	},
	deleteItem: async (id) => {
		return instance.put(`/admin/deals/item/${id}`, {
			active: false,
		});
	},
	getAllOrders: async () => {
		return instance.get(`/admin/deals/order`);
	},
	updateOrderById: async ({ id, payload }) => {
		return instance.put(`/admin/deals/order/${id}`, payload);
	},
	uploadImage: async (data) => {
		return instance.post(`/admin/deals/upload-image`, data);
	},
};
