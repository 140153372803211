import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
	Avatar,
	Box,
	Button,
	Chip,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	MenuItem,
	MenuList,
	Modal,
	Select,
	Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { AdminDealsAPI } from "app/routes/middleware";
import { HtmlTooltip, STATUSCODES, StyledDataGrid } from "app/utils/appHelpers";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Div from "@jumbo/shared/Div";
import { Columns } from ".";
import { GridActionsCellItem } from "@mui/x-data-grid";
import CancelIcon from "@mui/icons-material/Cancel";
import { ModalStyle } from "app/utils/constants/styles";

const OrdersTable = ({
	fetchedOrders,
	setIsOrderUpdateSuccess,
	setMessage,
	setSeverity,
	setOpenSnackbar,
}) => {
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm();

	console.log({ fetchedOrders });
	const [numberOfItems, setNumberOfItems] = React.useState(10);
	const [editedOrder, setEditedOrder] = React.useState({});
	const [editOrderOpen, setEditOrderOpen] = React.useState(false);
	const [cancelOrderOpen, setCancelOrderOpen] = React.useState(false);
	const [isSaving, setIsSaving] = React.useState(false);
	const [dateValue, setDateValue] = React.useState();
	const [updatedOrders, setUpdatedOrders] = React.useState([]);
	const [selectedCartItems, setSelectedCartItems] = React.useState({});
	const [show, setShow] = React.useState(false);

	const extraColumns = [
		{
			field: "cart",
			headerName: "Cart Items",
			width: 150,
			renderCell: (params) => {
				const Cart = params?.row?.cart?.cartItems
					? params?.row?.cart?.cartItems.map((x, i) => (
							// console.log( x.item.item )
							<MenuItem
								key={i}
								style={{ fontSize: 11 }}
								onClick={() =>
									handleCartItemsClick({
										image: x?.item.item.image,
										name: x?.item.item.name,
										quantity: x?.quantity,
										pricePerItem: x?.pricePerItem,
										discount: x?.item.discount,
									})
								}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										border: "1px solid #CFCFCF",
										padding: "5px 3px",
										fontSize: "11px",
									}}
								>
									<div>
										<Avatar
											src={x?.item.item.image || ""}
											alt="item"
											variant="circular"
											style={{ height: 24, width: 24 }}
										/>
									</div>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<div style={{ display: "flex", alignItems: "center" }}>
											<span style={{ fontWeight: 500 }}>&ensp; Name: </span>
											<span
												style={{
													width: "60px",
													color: "green",
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
												}}
											>
												&ensp;{x?.item.item.name}
											</span>
										</div>
										<div style={{ display: "flex", alignItems: "center" }}>
											<span style={{ fontWeight: 500 }}>&ensp; Quantity: </span>
											<span style={{ color: "green" }}>
												&ensp;{x?.quantity}
											</span>
										</div>
										<div style={{ display: "flex", alignItems: "center" }}>
											<span style={{ fontWeight: 500 }}>
												&ensp; Price Per Item:
											</span>
											<span style={{ color: "green" }}>
												&ensp;
												{x?.pricePerItem}
											</span>
										</div>
									</div>
								</div>
							</MenuItem>
					  ))
					: [];

				return (
					<>
						{Cart.length === 1 ? (
							<HtmlTooltip
								placement="left-start"
								title={
									<React.Fragment>
										<MenuList style={{ overflowY: "scroll", maxHeight: 300 }}>
											{Cart}
										</MenuList>
									</React.Fragment>
								}
							>
								<Chip
									label={`${Cart.length} Item`}
									style={{ color: "#F58320", fontSize: 12 }}
									variant="outlined"
								/>
							</HtmlTooltip>
						) : Cart.length > 1 ? (
							<HtmlTooltip
								placement="left-start"
								title={
									<React.Fragment>
										<MenuList style={{ overflowY: "scroll", maxHeight: 300 }}>
											{Cart}
										</MenuList>
									</React.Fragment>
								}
							>
								<Chip
									label={`${Cart.length} Items`}
									style={{ color: "#F58320", fontSize: 12 }}
									variant="outlined"
								/>
							</HtmlTooltip>
						) : (
							<Chip
								label={`No Item`}
								style={{ color: "#F58320", fontSize: 12 }}
								variant="outlined"
							/>
						)}
					</>
				);
			},
		},
		{
			field: "address",
			headerName: "Address",
			width: 250,
			renderCell: (params) => {
				return (
					<p
						style={{
							fontSize: "14px",
							fontWeight: 400,
							textTransform: "capitalize",
						}}
					>
						{params.row.cart.address}
					</p>
				);
			},
		},
		{
			field: "landmark",
			headerName: "Landmark",
			width: 200,
			renderCell: (params) => {
				return (
					<p
						style={{
							fontSize: "14px",
							fontWeight: 400,
							textTransform: "capitalize",
						}}
					>
						{params.row.cart.landmark}
					</p>
				);
			},
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			getActions: (params) => {
				return params.row.status === "CANCELLED" ||
					params.row.status === "DELIVERED"
					? []
					: [
							<GridActionsCellItem
								icon={<EditIcon />}
								label="Edit Row"
								className="textPrimary"
								onClick={() => {
									// console.log("row:", params.row);
									setEditedOrder(params.row);
									setEditOrderOpen(true);
								}}
								color="inherit"
							/>,
							<GridActionsCellItem
								icon={<CancelIcon style={{ color: "red" }} />}
								label="Edit Row"
								className="textPrimary"
								onClick={() => {
									// console.log("row:", params.row);
									setEditedOrder(params.row);
									setCancelOrderOpen(true);
								}}
								color="primary"
							/>,
					  ];
			},
		},
	];

	const handleCartItemsClick = (itemsData) => {
		setSelectedCartItems(itemsData);
		setShow(true);
	};

	const handleEditModalClose = () => {
		reset();
		setEditOrderOpen(false);
		setIsSaving(false);
	};

	React.useEffect(() => {
		if (editedOrder) {
			reset();
		}
	}, [editedOrder, reset]);

	const handleUpdateCatalogOrder = async (data) => {
		setIsSaving(true);

		const editedPayload = {
			status: data.status ? data.status : editedOrder?.status,
			deliveryDate: data.deliveryDate
				? dayjs(data.deliveryDate).toISOString()
				: editedOrder?.deliveryDate,
		};

		const deletePayload = {
			status: "CANCELLED",
		};

		const payload = editOrderOpen === true ? editedPayload : deletePayload;

		try {
			const request = await AdminDealsAPI.updateOrderById({
				id: editedOrder?.id,
				payload: payload,
			});

			if (STATUSCODES.SUCCESS.includes(request.status)) {
				setIsSaving(false);
				handleEditModalClose();
				setIsOrderUpdateSuccess(true);
				setOpenSnackbar(true);
				setMessage("Order updated successfully");
				setSeverity("success");

				const updatedOrderIndex = updatedOrders.findIndex(
					(order) => order.id === editedOrder.id
				);

				if (updatedOrderIndex !== -1) {
					const updatedOrdersCopy = [...updatedOrders];
					// console.log({ updatedOrdersCopy });
					updatedOrdersCopy[updatedOrderIndex] = {
						...updatedOrdersCopy[updatedOrderIndex],
						...payload,
					};
					setUpdatedOrders(updatedOrdersCopy);
				}
				setEditOrderOpen(false);
				setCancelOrderOpen(false);
			}
			setIsOrderUpdateSuccess(false);
		} catch (error) {
			// console.error(error);

			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Error updating order. Please make sure you've entered the right details or please reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry, there was an issue on our side. Please try again later or reach out to tech support."
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}

			setIsSaving(false);
		}
	};

	return (
		<div>
			<Box
				sx={{
					backgroundColor: "background.paper",
					borderRadius: 3,
					height: 600,
					width: "100%",
					"& .actions": {
						color: "text.secondary",
					},
					"& .textPrimary": {
						color: "text.primary",
					},
				}}
			>
				<StyledDataGrid
					style={{ padding: "0px 20px", borderRadius: "10px" }}
					rowHeight={80}
					rows={fetchedOrders}
					loading={fetchedOrders.length === 0}
					pageSize={numberOfItems}
					pagination={true}
					onProcessRowUpdateError={(error) => {
						setMessage(error.message);
						setSeverity("error");
						setOpenSnackbar(true);
					}}
					processRowUpdate={(params) => {
						// console.log(params);
						handleUpdateCatalogOrder(params?.props?.values);
					}}
					disableSelectionOnClick={true}
					rowCount={fetchedOrders?.length || 0}
					onPageSizeChange={(pageSize) => {
						setNumberOfItems(pageSize);
					}}
					columns={[
						...Columns.map(
							(obj) => extraColumns.find((o) => o.field === obj.field) || obj
						),
					]}
					rowsPerPageOptions={[10, 15, 20]}
					experimentalFeatures={{ newEditingApi: true }}
					sx={{
						boxShadow: 2,
						"& .MuiDataGrid-cell:hover": {
							color: "primary.main",
						},
					}}
					initialState={{
						sorting: {
							sortModel: [{ field: "status", sort: "desc" }],
						},
					}}
				/>
			</Box>
			{/* Edit Order Status Modal */}
			<Modal open={editOrderOpen}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 500,
						bgcolor: "background.paper",
						boxShadow: 24,
						p: 4,
						borderRadius: "8px",
						outline: "none",
						overflow: "auto",
						maxHeight: "95vh",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography variant="h5" sx={{ fontWeight: 600 }}>
							Editing Order
						</Typography>
						<IconButton onClick={handleEditModalClose}>
							<CloseIcon />
						</IconButton>
					</div>
					<form onSubmit={handleSubmit(handleUpdateCatalogOrder)}>
						<Controller
							name="status"
							control={control}
							render={({ field }) => (
								<Div
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										width: "100%",
										mt: "8px",
									}}
								>
									<FormControl fullWidth>
										<InputLabel id="demo-simple-select-label">
											Status
										</InputLabel>
										<Select
											defaultValue={editedOrder?.status || ""}
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											label="Status"
											onChange={(e) => {
												const value = e.target.value;
												field.onChange(value);
											}}
										>
											<MenuItem value={"PLACED"}>Placed</MenuItem>
											<MenuItem value={"DELIVERED"}>Delivered</MenuItem>
										</Select>
										<FormHelperText error>
											{errors.status && errors.status.message}
										</FormHelperText>
									</FormControl>
								</Div>
							)}
						/>
						<Controller
							name="deliveryDate"
							control={control}
							render={({ field }) => (
								<Div
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
									}}
								>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DateTimePicker"]}>
											<DateTimePicker
												sx={{ mr: 1 }}
												label="Delivery Date"
												fullWidth
												margin="normal"
												{...field}
												defaultValue={
													editedOrder?.deliveryDate
														? dayjs(editedOrder?.deliveryDate)
														: null
												}
												value={dateValue}
												InputLabelProps={{ shrink: true }}
												onChange={(newValue) => {
													setDateValue(newValue);
													field.onChange(newValue ? newValue : null);
												}}
											/>
										</DemoContainer>
									</LocalizationProvider>
									<FormHelperText error>
										{errors.deliveryDate && errors.deliveryDate.message}
									</FormHelperText>
								</Div>
							)}
						/>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								mt: 2,
							}}
						>
							<Button variant="outlined" color="primary" type="submit">
								{isSaving ? "Saving..." : "Save Changes"}
							</Button>
						</Box>
					</form>
				</Box>
			</Modal>
			<Modal open={cancelOrderOpen}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 500,
						bgcolor: "background.paper",
						boxShadow: 24,
						p: 4,
						borderRadius: "8px",
						outline: "none",
						overflow: "auto",
						maxHeight: "95vh",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography variant="h5" sx={{ fontWeight: 600 }}>
							Cancel Order{" "}
							<span style={{ color: "gray" }}>{editedOrder?.catalogName}</span>
						</Typography>
						<IconButton onClick={() => setCancelOrderOpen(false)}>
							<CloseIcon />
						</IconButton>
					</div>
					<Typography
						align="center"
						variant="h2"
						sx={{ fontWeight: 700, color: "red", my: 6 }}
					>
						Are you sure you want to cancel this order?
					</Typography>
					<Button
						variant="outlined"
						color="error"
						type="submit"
						onClick={handleSubmit(handleUpdateCatalogOrder)}
					>
						{isSaving ? "Canceling..." : "Cancel Order"}
					</Button>
				</Box>
			</Modal>
			<Modal
				open={show}
				onClose={() => setShow(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 500,
						bgcolor: "background.paper",
						border: "none",
						outline: "none",
						borderRadius: "12px",
						boxShadow: 24,
						p: 4,
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginBottom: "10px",
						}}
					>
						<Typography variant="h5" sx={{ fontWeight: 600 }}>
							Item Details
						</Typography>
						<IconButton onClick={() => setShow(false)}>
							<CloseIcon />
						</IconButton>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							border: "1px solid gray",
							padding: "10px 5px",
						}}
					>
						<div>
							<Avatar
								src={selectedCartItems.image || ""}
								alt="item"
								variant="circular"
								style={{ height: 100, width: 100 }}
							/>
						</div>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div style={{ display: "flex" }}>
								<span style={{ fontWeight: 500 }}>&ensp; Name: </span>
								<span
									style={{
										color: "green",
									}}
								>
									&ensp;{selectedCartItems.name}
								</span>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<span style={{ fontWeight: 500 }}>&ensp; Quantity: </span>
								<span style={{ color: "green" }}>
									&ensp;{selectedCartItems.quantity}
								</span>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<span style={{ fontWeight: 500 }}>&ensp; Price Per Item:</span>
								<span style={{ color: "green" }}>
									&ensp;
									{selectedCartItems.pricePerItem}
								</span>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<span style={{ fontWeight: 500 }}>&ensp; Discount:</span>
								<span style={{ color: "green" }}>
									&ensp;
									{selectedCartItems.discount}%
								</span>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default OrdersTable;
